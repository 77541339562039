import { Observable } from 'rxjs';
import {StorageUtil} from '../../helpers/storage-util';
import streamSaver from 'streamsaver';

export class FileStreams {

  public static downloadFile(url: string,sessionId:string, fileName:string): Observable<Response> {
    const localData = StorageUtil.getUserData();
    const currentToken = localData?localData.token:null;
  
    const headers = new Headers({
      'Authorization': 'Bearer ' + currentToken,
      'esp-plan-sessionId': sessionId
    });
    return new Observable((observer) => {
      fetch(url, {
        headers,
        method: 'GET',
      }).then((response) => {

        if (response.status !== 200) { 
          observer.error(new Error(response.statusText));
        } else {
          streamSaver.mitm = '/assets/filesaver/mitm.html';
          const fileStream = streamSaver.createWriteStream(fileName);
          response.body.pipeTo(fileStream);
          observer.next(response);
          observer.complete();
        }
      }).catch((error) => {
        console.log("download error");
        console.log(JSON.stringify(error));
        observer.error(error);
      });
    });
  }

}
