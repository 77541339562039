import { getLocaleNumberSymbol, NumberSymbol } from "@angular/common";
import {AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";

import {ICellEditorAngularComp} from "ag-grid-angular";
import { NumberUtils } from "src/app/common/utils/number.utils"


@Component({
    selector: "wfm-numeric-cell",
    templateUrl: "./numeric-editor.component.html",
    styleUrls: ["./numeric-editor.component.scss"]
})
export class NumericEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  public params: any;
  public value: string;
  public previousVal: any;
  public locale: string;
  private cancelBeforeStart = false;
  private cellmetadata: any;
  public allowIncremental: boolean = false;
  public allowedChars:string = "";
  private invokingChars:string = "";
  public decimalSeparator:string = "";
  private groupSeparator:string = "";

  @ViewChild("input", {read: ViewContainerRef}) public input;

  agInit(params: any): void {
    this.previousVal = params.value;
    this.locale = params.locale;
    this.decimalSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Decimal);
    this.groupSeparator = getLocaleNumberSymbol(this.locale, NumberSymbol.Group);

    this.allowIncremental = params.incremental ? params.incremental : false;
    const NUMBERS_AND_DECIMAL = "1234567890" + this.decimalSeparator;
    this.allowedChars  = (this.allowIncremental) ? NUMBERS_AND_DECIMAL + "+-%" : NUMBERS_AND_DECIMAL;
    this.invokingChars = (this.allowIncremental) ? NUMBERS_AND_DECIMAL + "+-"  : NUMBERS_AND_DECIMAL;


    const isAllowed = params.charPress && (this.allowedChars.indexOf(params.charPress) >= 0);
    this.params = params;
    if (isAllowed) {
      this.params.value = params.charPress;
    }

    if (params.charPress || this.params.value === null) {
      this.value = this.params.value;
    } else {
      // Format according to the locale and remove group separator to be ready for edition.
      this.value = NumberUtils.formatNumberIfNecessary(this.params.value, this.locale, params.metadata.decimal);
      const regExp = this.groupSepRegExp(this.groupSeparator);
      this.value = this.value.replace(regExp, "");
    }

    // only start edit if key pressed is part of invoking invokingChars set, not a letter
    this.cancelBeforeStart = params.context.isLoading() || (params.charPress && (this.invokingChars.indexOf(params.charPress) < 0));

    if (this.params.column && this.params.column.colDef) {
      const field = this.params.column.colDef.field;
      if (this.params.metadata.cellmetadata) {
        this.cellmetadata = this.params.metadata.cellmetadata[field];
      } else {
        this.cellmetadata = {

        }
      }

    }
  }

  groupSepRegExp(groupSep: string): RegExp {
    if (groupSep === ".") {
      return new RegExp("\\.", "g");
    }
    return new RegExp(groupSep, "g");
  }

  getValue(): any {
    if (this.params.zeroOnEmpty && (this.value === null || this.value === "")) {
      return 0;
    }
    return this.value;
  }

  // for testing
  setValue(newValue: any) {
    this.value = newValue;
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // Gets called once when editing is finished.
  isCancelAfterEnd(): boolean {
    // once the user is done with editing we have to use
    // decimal point to perform math operations.
    if (this.decimalSeparator !== NumberUtils.DECIMAL_POINT) {
      this.value = this.value.replace(this.decimalSeparator, NumberUtils.DECIMAL_POINT);
    }


    if (this.isIncrementalEdit()) this.calculate();
    const isValidNumberOrEmpty:boolean = this.isValidNumberOrEmpty();
    const isValid = this.params.metadata.validator.isValid(this.value, this.cellmetadata);
    if (!isValid) {
      const titleKey = this.params.metadata.validator.title;
      const msgKey = this.params.metadata.validator.msg;
      if (this.params.context.showMessage) {
        this.params.context.showMessage(titleKey, msgKey, "error");
      }
    }
    return !isValid || !isValidNumberOrEmpty;
  }

  calculate() {
    const num = this.isPercentage() ? this.value.slice(0, -1) : this.value;
    const val: number = Number.parseFloat(num);
    let newVal: number = this.previousVal ? Number.parseFloat(this.previousVal) : 0;
    newVal += this.isPercentage() ? val / 100 * this.previousVal : val;
    const decimal = this.params.metadata.decimal;
    const precision = Math.pow(10, decimal);
    newVal = Number(newVal) ? Math.round(Number(newVal) * precision) / precision : newVal;
    this.value = newVal.toFixed(decimal);
  }

  isValidNumberOrEmpty(){
    if(this.value === "") return true;
    const decimal = this.params.metadata.decimal;
    const precision = Math.pow(10, decimal);
    const newVal = Number(Math.floor(Number(this.value + "e+" + decimal)) + "e-" +decimal)


    this.value = newVal.toFixed(decimal);
    return this.previousVal != this.value;
  }

  isIncrementalEdit() {
    this.value += '';
    return  this.value.startsWith("+") || this.value.startsWith("-");
  }
  isPercentage() {
    return this.value && this.value.endsWith("%");
  }

  onKeyDown(event): void {
    // let isWarnShown = this.p.isOpen();
    const keyCode = ("which" in event) ? event.which : event.keyCode;
    if (keyCode === 9) { // HORIZONTAL TAB
      this.params.api.stopEditing();
      // this.params.api.clearFocusedCell();
      if (event.shiftKey)
        this.params.api.tabToPreviousCell();
      else
        this.params.api.tabToNextCell();
      event.stopPropagation();
    } else if (keyCode===13) { // ENTER
      this.params.api.stopEditing();
      let currentFocusedCell = this.params.api.getFocusedCell();
      if (currentFocusedCell) {
        this.params.api.setFocusedCell(currentFocusedCell.rowIndex, currentFocusedCell.column.getColId(), null);
      }
    }

    const keyAllowed = this.isKeyAllowed(event);
    const multiplePeriod = this.hasMultipleDecimal(event);
    const invalidPlusMinus = this.hasInvalidPlusMinus(event);
    const invalidPercent = this.hasInvalidPercent(event);
    const charDisallowed = this.charDisallowed(event);
    if (!keyAllowed || multiplePeriod || invalidPlusMinus || invalidPercent || charDisallowed) {
      if (event.preventDefault) { event.preventDefault(); }
    }
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.input && this.input.element && this.input.element.nativeElement)
        this.input.element.nativeElement.focus();
    }, 0);

  }

  hasMultipleDecimal(event) {
    const nativeElm = this.input.element.nativeElement;
    const inputVal = nativeElm.value;
    //Fix for bug ESP-2753, uncomment and try it out
    if(this.isDecimalHighlighted(nativeElm)){
      return false;
    }
    return inputVal.indexOf(this.decimalSeparator) >= 0 && event.key === this.decimalSeparator;
  }

  private isDecimalHighlighted(nativeElm){
    const inputVal = nativeElm.value;
    const highlighted = inputVal.substr(nativeElm.selectionStart,
      nativeElm.selectionEnd - nativeElm.selectionStart);

    if(highlighted.indexOf(this.decimalSeparator) >= 0){
      return true;
    }
    return false;

  }

  hasInvalidPlusMinus(event) {
    const inputVal = this.input.element.nativeElement.value;
    const wrongPosition = (event.key == "+" || event.key == "-") && event.target.selectionStart != 0;
    const alreadyContains = (inputVal.startsWith("+") || inputVal.startsWith("-")) && (event.key == "+" || event.key == "-");
    return wrongPosition || alreadyContains;
  }

  hasInvalidPercent(event) {
    const inputVal = this.input.element.nativeElement.value;
    const wrongPosition = event.key == "%"
    && (event.target.selectionStart != inputVal.length  || !this.isIncrementalEdit());
    const alreadyContains = inputVal.endsWith("%") && event.target.selectionStart == inputVal.length && this.allowedChars.indexOf(event.key)>-1;
    return wrongPosition || alreadyContains ;
  }

  charDisallowed(event){
    return "_=".indexOf(event.key)>-1;
  }

  isKeyAllowed(event) {

    const keyCode = ("which" in event) ? event.which : event.keyCode;

    const isNumeric = this.allowedChars.indexOf(event.key)>-1;

    const isOtherAllowed = (keyCode == 8 // BACKSPACE
                            || keyCode == 46 // DELETE
                            || keyCode == 40 // DOWN
                            || keyCode == 39 // RIGHT
                            || keyCode == 38 // UP
                            || keyCode == 37 // LEFT
                            || keyCode == 27 // ESCAPE
                            || (keyCode == 65 && event.ctrlKey == true) // CTRL+A
                            || (keyCode == 65 && event.metaKey == true) // CMD+A
      //|| keyCode == 13 // ENTER
                            );
    const isIncrementEditKeys =  this.allowIncremental && this.allowedChars.indexOf(event.key)>-1;

    return isNumeric || isOtherAllowed || isIncrementEditKeys;
  }

  onLoaded($event: Event) {
    //debugger;
  }
}
