import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {of, Observable, lastValueFrom} from "rxjs";
import {map, delay, catchError} from "rxjs/operators";
import {environment} from "../environments/environment";
import {Store} from "@ngrx/store";
import {AppActionTypes, RefreshedTokenFailure, UpdateLocale, UpdateLoginState, UpdateUserInfo} from "./store/actions";
import jwt_decode from "jwt-decode";
import {appSettings} from "./app-settings";
import { StorageUtil } from "./helpers/storage-util";
import {UserData} from "./models/user";

@Injectable({
  providedIn: "root"
})
export class AuthenticationService {
  private _userData: UserData = null;
  private tokenRefreshCountdown = 45 * 60 * 1000;
  private tokenNewBrowserThreshold = 1 * 60 * 1000;
  private isRefreshingToken = false;
  private isLoading = false;
  private hasEntities = false;
  private featureList:Array<String> = [];

  public ssoToken: any = null;
  public ssoLocale: any = null;
  public ssoLineOfBusiness: any = null;
  public loggedIn: EventEmitter<boolean> = new EventEmitter<boolean>();
  //private loggedIn = this.store.pipe(select('state'));
  //public userInfo: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient, private store: Store<any>) {

  }

  ssoAuthenticate(tenantIdentifier) {
    this._userData = null;
    return new Promise((resolve, reject) => {
      if (!environment.locales.find(x => x.locale === this.ssoLocale)) {
        this.ssoLocale = environment.defaultLocale;
      }
      ;
      this.ssoAuthenticateUserByToken(this.ssoToken, this.ssoLocale, tenantIdentifier)
        .toPromise()
        .then(data => {
            this.saveUserDataFromResponse(data);
            resolve(this._userData);
          }
        )
        .catch(err => {
            this._userData = null;
            this.store.dispatch(new UpdateLoginState(false));
            reject(err);
          }
        );
    });
  }

  async cxOneAuthenticate(username,token,locale){
    let apiURL = environment.saasEndpointBaseUrl + appSettings.resourceLocations.cxOneLogin;

    let response = await lastValueFrom(this.http.post(apiURL, {userName:username,cxOneToken:token,locale},{withCredentials:true}));
    this.saveUserDataFromResponse(response);
    return this._userData;
  }
  authenticate(username, password, currentLang, tenantIdentifier) {
    this._userData = null;
    return new Promise((resolve, reject) => {
      this.authenticateUser(username, password, currentLang, tenantIdentifier)
        .toPromise()
        .then(data => {
            this.saveUserDataFromResponse(data);
            resolve(this._userData);
          }
        )
        .catch(err => {
            this._userData = null;
            this.store.dispatch(new UpdateLoginState(false));
            reject(err);
          }
        );
    });
  }

  authorizeUser() {
    this.isLoading = true;
    const entPermProm = this.entityList();
    const featToggProm = new Promise((resolve, reject) => {
      const apiURL = environment.saasEndpointBaseUrl + appSettings.resourceLocations.featureList +"?application=ESP";
      this.http.get(apiURL).toPromise()
        .then((data:any) => {
            this.saveFeatures(data.features);
            resolve(true);
          }
        )
        .catch(err => {
            resolve(true);
          }
        );
    });
    return Promise.all([entPermProm, featToggProm]).then(()=>this.isLoading = false);
  }

  entityList() {
    return new Promise((resolve, reject) => {
      const apiURL = environment.espEndpointBaseUrl + appSettings.resourceLocations.entityList;
      this.http.post(apiURL, {}).toPromise()
        .then(data => {
            this.saveHasEntities();
            resolve(true);
          }
        )
        .catch(err => {
            reject(err);
          }
        );
    });
  }

  logout() {
    this.userLogout().then().catch(err => {
      console.log("logout error");
      console.error(err);
    });

    this._userData = null;
    StorageUtil.clearLocalStorage();
    // localStorage.clear();
    this.store.dispatch(new UpdateUserInfo(this._userData));
    this.store.dispatch(new UpdateLoginState(false));
  }

  authenticateUser(userName, password, locale, tenantIdentifier) {
    let apiURL = environment.saasEndpointBaseUrl + appSettings.resourceLocations.userLogin;

    //WFM-41073 : restrict master login in the UI (will be moved to the server side soon)
    if(userName && userName.toLowerCase()=="master"){
      return Observable.create((observer)=>{
        setTimeout(()=>{
          observer.error("Unauthorized user");
        },1000);
      });
    }

    return this.http.post(apiURL, {userName, password, locale, tenantIdentifier},{withCredentials:true});
  }

  ssoAuthenticateUserByToken(ssoToken, locale, tenantIdentifier) {
    let apiURL = environment.saasEndpointBaseUrl + appSettings.resourceLocations.userLogin;
    return this.http.post(apiURL, {ssoToken, locale, tenantIdentifier},{withCredentials:true});
  }

  issueToken() {
    let apiURL = environment.saasEndpointBaseUrl + appSettings.resourceLocations.userTokenRefresh;
    return this.http.get(apiURL);
  }

  get userData(): any {
    return this._userData;
  }

  get hasToken() {
    return this._userData && this._userData.token !== null && this._userData.token != "";
  }

  refreshToken() {
    if (!this.isRefreshingToken) {
      //this.isRefreshingToken = true;
      return this.issueToken().pipe(
        map(data => {
            this.saveUserDataFromResponse(data);
            this.isRefreshingToken = false;
            return {type: AppActionTypes.RefreshedTokenReceived, payload: this._userData["token"]};
          }
        ),catchError((error) => {
          return of({ type: AppActionTypes.RefreshedTokenFailure, payload: null });
        })
      );
    }
  }

  refreshTokenIfNecessary() {
    if (!this.isRefreshingToken && this._userData && this._userData.expirationTimestamp < (this.currentTimestamp() + this.tokenRefreshCountdown)
    ) {
      return this.refreshToken();
    } else {
      return of({type: AppActionTypes.NoAction, payload: null});
    }
  }

  getTimeUntilTokenExpiration():number {
    if (!this.verifyTokenExistsInStorage()) {
      return 0;
    }
    var timeUntilTokenExpiration = null;
    if (this._userData) {
      timeUntilTokenExpiration = this._userData.expirationTimestamp - this.currentTimestamp();
    }
    return timeUntilTokenExpiration;
  }

  public isTokenExpired():boolean {
    return this.getTimeUntilTokenExpiration() < this.tokenNewBrowserThreshold;
  }

  currentTimestamp() {
    var currentDate = new Date();
    return currentDate.getTime();
  }

  verifyTokenExistsInStorage() {
    return !(StorageUtil.getUserData() === null);
  }

  getTokenFromStorage() {
    let userData = StorageUtil.getUserData();
    if (userData && userData.token && userData.token !== null && userData.token !== "") {
      this._userData = userData;
      if (this.getTimeUntilTokenExpiration() < this.tokenNewBrowserThreshold) {
        this.logout();
        return false;
      }
      if (this._userData.locale) {
        this.store.dispatch(new UpdateLocale(this._userData.locale));
      }
      this.store.dispatch(new UpdateUserInfo(this._userData));
      this.store.dispatch(new UpdateLoginState(true));
      return true;
    }
    return false;
  }

  checkEntitiesFromStorage() {
    let hasEntities = StorageUtil.hasEntities();
    if (hasEntities) {
      this.hasEntities = true;
      return true;
    }
    return false;
  }

  saveUserDataFromResponse(data) {
    if (this._userData == null) {
      this._userData = new UserData();
    }
    let expirationTimestamp = this.currentTimestamp() + (data["tokenExpirationTimeSec"] * 1000);
    this._userData = {...this._userData,
      token: data["access-token"],
      locale: jwt_decode<any>(data["access-token"]).locale,
      language : jwt_decode<any>(data["access-token"]).locale.substring(0, 2),
      idpType: jwt_decode<any>(data["access-token"]).idpType,
      expirationTimestamp: expirationTimestamp}
    // this._userData.token = data["access-token"];
    // this._userData.locale = jwt_decode(data["access-token"]).locale;
    // this._userData.language = jwt_decode(data["access-token"]).locale.substring(0, 2);
    // this._userData.expirationTimestamp = expirationTimestamp;
    if (data["otherParameters"]) {
      this._userData.username = data["otherParameters"].USER_NAME;
      this._userData.firstName = data["otherParameters"].FIRST_NAME;
      this._userData.lastName = data["otherParameters"].LAST_NAME;
      this._userData.espPermission = data["otherParameters"].ESP_PERMISSIONS;
      this._userData.userRoles = data["otherParameters"].USER_ROLES;
      this._userData.publicApiFeature = data["otherParameters"].PUBLIC_API_FEATURE_ENABLED;
    }
    StorageUtil.setUserData(this._userData);
    if (this._userData.locale) {
      this.store.dispatch(new UpdateLocale(this._userData.locale));
    }
    this.store.dispatch(new UpdateUserInfo(this._userData));
    this.store.dispatch(new UpdateLoginState(true));
    return true;
  }

  saveHasEntities() {
    StorageUtil.setHasEntities(true);
    //localStorage.setItem("hasEntities", "true");
    return true;
  }

  saveFeatures(data){
    StorageUtil.setFeatures(data);
    return true;
  }

  isLoggedIn() {
    // Emits this event when we refresh without closing the web browser
    if (!this.hasToken) {
      this.getTokenFromStorage();
    }
    //console.log(this.getTimeUntilTokenExpiration());
    //if (this.getTimeUntilTokenExpiration() <= 0) {
    //  this.logout();
    //  return false;
    //}
    return this.hasToken;
  }

  isAuthorized() {

    if (!this.hasEntities) {
      this.checkEntitiesFromStorage();
    }
    return this.isLoggedIn() && this.hasEntities;
  }

  isAuthenticated() {
    return this.isLoggedIn();
  }

  hasFeature(feature:any):boolean {
    if (this.featureList.length === 0) {
      this.featureList = JSON.parse(StorageUtil.getFeatures()) || this.featureList;
    }
    return this.featureList.includes(feature);
  }

  retrieveAuthenticatedUser(jtid: string) {
    return new Promise( (resolve, reject) => {
      const retrieveAuthenticatedUserUrl = environment.saasEndpointBaseUrl + appSettings.resourceLocations.retrieveAuthenticatedUser;
      this.http.post(retrieveAuthenticatedUserUrl, { "jtid" : jtid }, {withCredentials:true}).toPromise()
        .then(samlSsoResponse => {
          this.saveUserDataFromResponse(samlSsoResponse);
          this.saveFeatures(samlSsoResponse["enabledFeatures"]);
          resolve(this._userData);
        }).catch(err => {
          this._userData = null;
          this.store.dispatch(new UpdateLoginState(false));
          reject(err);
        });
    });
  }

  userLogout() {
    return new Promise( (resolve, reject) => {
      const logoutFromWfmIexWebStationUrl = environment.saasEndpointBaseUrl + appSettings.resourceLocations.userLogout;
      this.http.post(logoutFromWfmIexWebStationUrl, null, {withCredentials:true}).toPromise()
        .then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        })
    });
  }


}
