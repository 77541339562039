import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { ImportedForecastsListPageComponent } from "./pages";
import { AuthenticationGuard } from "src/app/authentication.guard";
import {languageGuard} from './language.guard';

export const ImportedForecastsRoutes: Routes = [{
  path: "imported-forecasts",
  children: [
    {
      path: "",
      component: ImportedForecastsListPageComponent
    }
  ],
  canActivate: [AuthenticationGuard,languageGuard]
}];

@NgModule({
  imports: [
    RouterModule.forChild(ImportedForecastsRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ImportedForecastsRoutingModule { }
