import { CanActivateFn } from '@angular/router';
import {Store} from '@ngrx/store';
import { inject } from '@angular/core';
import { map } from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';


export const languageGuard: CanActivateFn = (route, state) => {
  let store = inject(Store);

  let state$ = store.select("state");

  return state$.pipe(
    filter((state: any) => {
      return !state.isLoadingLanguage;
    }),
    map((state: any) => {
      return true;
  }));
};
