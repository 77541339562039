import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { PlanListPageComponent } from "./pages";
import { PlanDetailComponent } from "./pages/plan/plan-detail/plan-detail.component";
import { AuthenticationGuard } from "src/app/authentication.guard";
import { CanDeactivateGuard } from "./helpers/can-deactivate-guard.service";
import { PlanDistributionComponentV2 } from "./pages/plan/plan-distribution/plan-distribution-v2.component";
import {PlanDetailComponentV4} from './pages/plan/plan-detail-v4/plan-detail-v4.component';
import {PlanDetailComponentV5} from './pages/plan/plan-detail-v5/plan-detail-v5.component';
import {CtSettingsComponent} from './pages/plan/ct-settings/ct-settings.component';
import {languageGuard} from './language.guard';

export const planRoutes: Routes = [{
  path: "plans",
  children: [
    {
      path: "",
      component: PlanListPageComponent,
    },
    {
      path: ":id",
      component: PlanDetailComponent,
      canDeactivate: [CanDeactivateGuard]
    }
  ],
  canActivate: [AuthenticationGuard,languageGuard]
},
{
    path: "site-plans-v4",
    children: [
      {
        path: ":id",
        component: PlanDetailComponentV4,
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path:"distribution",
            component: PlanDistributionComponentV2,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path:"ct-settings",
            component: CtSettingsComponent,
            canDeactivate: [CanDeactivateGuard],
          },

        ]
      }
    ],
    canActivate: [AuthenticationGuard,languageGuard]
  },
  {
    path: "site-plans-v5",
    children: [
      {
        path: ":id",
        component: PlanDetailComponentV5,
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path:"distribution",
            component: PlanDistributionComponentV2,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path:"ct-settings",
            component: CtSettingsComponent,
            canDeactivate: [CanDeactivateGuard],
          },

        ]
      }
    ],
    canActivate: [AuthenticationGuard,languageGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(planRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PlanRoutingModule { }
