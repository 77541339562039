// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* modal dialogs */
.modal-header {
  font-family: "Open Sans", Arial, sans-serif;
  border-bottom: 1px solid #DAE2E8;
  border-radius: 0;
}

.modal-body {
  min-height: 445px;
  background-color: #e9f1f5;
  padding: 20px;
  margin-left: 1px;
}

.modal-header .close {
  margin-top: 0px;
  opacity: 0.8;
  color: #afc1cf;
  padding: 0rem;
  margin: 0rem 0rem 0rem auto;
}

.modal-header .back {
  background-color: transparent;
  border: 0;
}

.modal-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: #404040;
  margin-left: 5px;
}

.modal-header .close:hover, .modal-header .close:focus {
  opacity: 1;
  color: #afc1cf;
}

.modal-footer {
  padding: 12px 8px;
  border-top: solid 1px #d0d5d9;
}

/* error message */
.b-error .modal-header {
  border-bottom: solid 1px #fa3f40;
}

.b-error .ib-alert {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

/* warning message */
.b-warning .modal-header {
  border-bottom: solid 1px #f15d22;
}

.b-warning .ib-warning {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

/* info message */
.b-info .modal-header {
  border-bottom: solid 1px #43acd9;
}

.b-info .ib-info {
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

.icon-Close {
  font-family: "icomoon" !important;
  font-weight: lighter;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #93A4AA;
}

.radio {
  padding-left: 5px;
  line-height: 20px;
  margin-right: 35px;
}

.radio label {
  position: relative;
  padding-left: 5px;
}

.radio label:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  transition: border 0.15s ease-in-out;
}

.radio label:after {
  position: absolute;
  content: " ";
  width: 7px;
  height: 7px;
  left: 5px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #237bc2;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type=radio] {
  opacity: 0;
  z-index: 1;
}

.radio input[type=radio] + label:hover:before {
  border-color: #237bc2;
}

.radio input[type=radio]:focus + label:before {
  outline: 0;
}

.radio input[type=radio]:checked + label:after {
  transform: scale(1, 1);
}

.radio input[type=radio]:disabled + label {
  opacity: 0.65;
  cursor: default;
}

.radio input[type=radio]:disabled + label:hover:before {
  border-color: #ccc;
}

.radio input[type=radio]:disabled + label:before {
  cursor: not-allowed;
}

.radio input[type=radio]:checked:disabled + label:after {
  background-color: #C7D3D9;
}

.radio input[type=radio],
.radio-inline input[type=radio] {
  margin-left: 0;
}

::ng-deep .wfm-modal .modal-content create-plan-cxone {
  border-radius: 0;
  min-height: 521px;
  width: 540px;
  border: 1px solid #DAE2E8;
}
::ng-deep .wfm-modal .modal-header {
  height: 80px;
  align-items: center;
  border-bottom: 1px solid #DAE2E8;
}
::ng-deep .wfm-modal .modal-footer {
  height: 80px;
  border-top: solid 1px #d0d5d9;
}
::ng-deep .wfm-modal .inactive {
  display: none;
}

.multiselect-bg-gray {
  background-color: #F7F8FA;
  outline: 0px;
}

.item-separator {
  border-top: 1px solid #c1bebe;
}

.multiselect-bg-white {
  background-color: white;
  outline: 0px;
}

.radio-wrapper {
  margin-bottom: 10px;
}

.show-error {
  display: block;
}

p-multiSelect {
  width: 400px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
