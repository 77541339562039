import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthenticationGuard } from "src/app/authentication.guard";
import { CanDeactivateGuard } from "./helpers/can-deactivate-guard.service";
import {HistoricalDataComponent} from "./pages/historical-data/historical-data.component";
import {languageGuard} from './language.guard';

export const HistoricalDataRoutes: Routes = [{
  path: "historical-data",
  children: [
    {
      path: "",
      component: HistoricalDataComponent,
      canDeactivate: [CanDeactivateGuard]

    }
  ],
  canActivate: [AuthenticationGuard,languageGuard]
}];

@NgModule({
  imports: [
    RouterModule.forChild(HistoricalDataRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class HistoricalDataRoutingModule { }
